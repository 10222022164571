import React from 'react';
import {
  Button,
  Col, Form, Input, Row, Typography,
} from 'antd';
import { Link } from 'react-router-dom';

const RecoverPassword = () => (
  <Row justify="center" align="middle" style={{ height: '100%' }}>
    <Col span={12}>
      <Typography.Title level={1}>Recover Password </Typography.Title>
      <Typography.Text>We’ll e-mail you instructions on how to reset your password.</Typography.Text>

      <Form
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        autoComplete="off"
        className="mt-32"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item className="mt-16">
          <Button block type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>
      </Form>

      <Row>
        <Typography.Paragraph>
          <Typography.Text className="mr-4">Go back to</Typography.Text>
          <Link to="/">
            Login
          </Link>
        </Typography.Paragraph>
      </Row>
    </Col>
  </Row>
);
export default RecoverPassword;
