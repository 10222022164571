import React from 'react';
import {
  Col, Row, Typography,
} from 'antd';

const Dashboard = () => (
  <Row className="mb-24">
    <Col>
      <Typography.Title level={3}>Welcome back, Umar 👋</Typography.Title>
      <Typography.Text>Your current status and analytics are here</Typography.Text>
    </Col>
  </Row>
);
export default Dashboard;
