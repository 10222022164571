import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  Layout, Row, Col, Typography, Avatar,
} from 'antd';
import { Authentication } from '../../components/Illustrations';

const { Content } = Layout;

const Public = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Content className="bg-white">
      <Row style={{ height: '100vh' }}>
        <Col span={12} className="bg-primary-1">
          <Row justify="center" align="middle" style={{ height: '100%' }}>
            <div
              className="logo"
              style={{
                margin: '64px',
                top: '0',
                position: 'absolute',
                left: '0',
              }}
            >
              <Avatar
                shape="square"
                size="large"
                className="bg-primary"
              >
                V
              </Avatar>
              <Typography.Title level={3}>VOUCH</Typography.Title>
            </div>

            <Col span={18} className="text-center">
              <Authentication />
              <Typography.Title level={2}>Very good solutions are waiting for you 🤞</Typography.Title>
              <Typography.Text>
                Invoice scanning and certificate system with receipt management and digital archive via
                the cloud. Integrated with most financial systems on the market.
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Outlet />
        </Col>
      </Row>

    </Content>
  </Layout>
);
export default Public;
