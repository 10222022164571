import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import Header from '../../components/Header';

const { Content } = Layout;

const Private = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Navigation />
    <Layout>
      <Header />
      <Content className="mr-24 mb-24 ml-24">
        <Outlet />
      </Content>
    </Layout>
  </Layout>
);

export default Private;
