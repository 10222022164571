import React from 'react';
import Error from '../../components/Error';
import { Error502 as Error502Illustration } from '../../components/Illustrations';

const Error502 = () => (
  <Error
    Image={<Error502Illustration />}
    Code="502"
    Message="Bad Gateway"
  />
);

export default Error502;
