import React from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

const Error = ({ Image, Code, Message }: any) => (
  <>
    {Image}
    <Typography.Title level={1} className="mb-0 mb-32">{Code}</Typography.Title>
    <Typography.Title level={1} className="mt-0">{Message}</Typography.Title>
    <Typography.Paragraph className="mb-32">You can go back home.</Typography.Paragraph>
    <Link to="/">
      <Button type="primary">Back to Home</Button>
    </Link>
  </>
);

export default Error;
