import React, { useState } from 'react';
import {
  Menu, Layout, Typography, Avatar, Row, Col,
} from 'antd';
import {
  DashboardTwoTone,
  DollarTwoTone, FundTwoTone, MenuFoldOutlined, MenuUnfoldOutlined, SettingTwoTone,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Layout.Sider
      width={280}
      theme="light"
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      className="site-navigation"
    >
      <div className="logo-container">
        <Row align="middle" justify={!isCollapsed ? 'space-between' : 'center'}>
          <Col order={isCollapsed ? 2 : 1}>
            <div className="logo">
              <Avatar
                shape="square"
                size="large"
                className={`bg-primary ${isCollapsed ? 'mt-16' : 'mt-0'}`}
              >

                V
              </Avatar>
              {!isCollapsed && (
                <Typography.Title level={3}>VOUCH</Typography.Title>
              )}
            </div>
          </Col>
          <Col order={isCollapsed ? 1 : 2}>
            <Typography.Text
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
            >
              {!isCollapsed && (
                <MenuFoldOutlined />
              )}

              {isCollapsed && (
                <MenuUnfoldOutlined />
              )}
            </Typography.Text>
          </Col>
        </Row>
      </div>

      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        className="site-menu"
      >
        <Menu.Item key="site-menu-1" icon={<DashboardTwoTone twoToneColor="#00AB55" />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>

        <Menu.Item key="site-menu-2" icon={<DollarTwoTone twoToneColor="#00AB55" />}>
          <Link to="/invoice">
            Invoices
          </Link>
        </Menu.Item>

        {/* <Menu.SubMenu key="site-menu-sub23" icon={<DollarTwoTone twoToneColor="#00AB55" />} title="Invoices"> */}
        {/*  <Menu.Item key="site-menu-15"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>Copy Forwarding</Typography.Text> */}
        {/*      <Badge count={16} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-16"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>Past Due Invoices</Typography.Text> */}
        {/*      <Badge count={12} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-17"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>Check After Attest</Typography.Text> */}
        {/*      <Badge count={10} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-18"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>Error Queue</Typography.Text> */}
        {/*      <Badge count={32} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-28"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>New Vendor</Typography.Text> */}
        {/*      <Badge count={21} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-38"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>New Invoice Review</Typography.Text> */}
        {/*      <Badge count={13} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-48"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>Credit Memo</Typography.Text> */}
        {/*      <Badge count={37} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-58"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>Invoice Exceptions Review</Typography.Text> */}
        {/*      <Badge count={26} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/*  <Menu.Item key="site-menu-68"> */}
        {/*    <Link to="/invoice"> */}
        {/*      <Typography.Text>Queue for Automatic Steps</Typography.Text> */}
        {/*      <Badge count={54} /> */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/* </Menu.SubMenu> */}
        <Menu.Item key="site-menu-3" icon={<FundTwoTone twoToneColor="#00AB55" />}>
          <Link to="/report">Reports</Link>
        </Menu.Item>
        <Menu.Item key="site-menu-4" icon={<SettingTwoTone twoToneColor="#00AB55" />}>
          <Link
            to="/"
          >
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};
export default Navigation;
