import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { Public, Private, Error } from '../layouts/index';
import Dashboard from './Dashboard';
import Invoice from './Invoice';
import InvoiceDetail from './Invoice-Detail';
import Report from './Report';
import Login from './Login';
import RecoverPassword from './Recover-Password';
import ResetPassword from './Reset-Password';
import {
  Error403,
  Error404,
  Error500,
  Error502,
  Error503,
} from './Server-Errors';

export default () => (
  <Routes>
    <Route element={<Error />}>
      <Route path="/forbidden" element={<Error403 />} />
      <Route path="*" element={<Error404 />} />
      <Route path="/internal-error" element={<Error500 />} />
      <Route path="/bad-gateway" element={<Error502 />} />
      <Route path="/service-unavailable" element={<Error503 />} />
    </Route>
    <Route path="/" element={<Public />}>
      <Route path="/" element={<Login />} />
      <Route path="/recover-password" element={<RecoverPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Route>
    <Route path="/" element={<Private />}>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/invoice" element={<Invoice />} />
      <Route path="/invoice/:invoiceNumber" element={<InvoiceDetail />} />
      <Route path="/report" element={<Report />} />
    </Route>
  </Routes>
);
