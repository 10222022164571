import React from 'react';
import Error from '../../components/Error';
import { Error503 as Error503Illustration } from '../../components/Illustrations';

const Error503 = () => (
  <Error
    Image={<Error503Illustration />}
    Code="503"
    Message="Something went wrong."
  />
);

export default Error503;
