import React from 'react';
import {
  Avatar, Divider, Dropdown, Menu, Layout, Col, Row, Typography, Select,
} from 'antd';
import { UserOutlined } from '@ant-design/icons/';

const userMenu = (
  <Menu>
    <Menu.Item key="user-menu-1" icon={<UserOutlined />}>
      Settings
    </Menu.Item>
    <Menu.Item key="user-menu-2" icon={<UserOutlined />}>
      Profile
    </Menu.Item>
    <Menu.Item key="user-menu-3" icon={<UserOutlined />}>
      Change Password
    </Menu.Item>
    <Divider className="m-0" />
    <Menu.Item key="user-menu-4" icon={<UserOutlined />}>
      Logout
    </Menu.Item>
  </Menu>
);

const Header = () => (
  <Layout.Header className="site-header m-24 pl-24 pr-24">
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Paragraph className="notification">
          Do you know the latest update of 2022? 🎉
          <Typography.Text>
            Our roadmap is alive for future
            updates.
          </Typography.Text>
        </Typography.Paragraph>
      </Col>
      <Col>
        <Select defaultValue="NDE Offshore AB" size="large" className="mr-16" style={{ width: '180px' }}>
          <Select.Option value="All">All</Select.Option>
          <Select.Option value="NDE Offshore AB">NDE Offshore AB</Select.Option>
          <Select.Option value="Techalica">Techalica</Select.Option>
        </Select>
        <Dropdown overlay={userMenu} placement="bottomRight" arrow>
          <Avatar size={40}>US</Avatar>
        </Dropdown>
      </Col>
    </Row>
  </Layout.Header>
);

export default Header;
