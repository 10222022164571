import React, { useEffect, useState } from 'react';
import {
  PageHeader,
  Table,
  // Tag,
  // TagType,
} from 'antd';

import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

interface InvoiceInterface {
    invoiceNumber: number,
    vendor: string,
    dueDate: string,
    amount: string,
    currency: string,
    // status: TagType,
    // message: TagType,
}

const getColumns = () => {
  const columns: ColumnsType<InvoiceInterface> = [
    {
      title: 'Invoice',
      dataIndex: 'invoiceNumber',
      sorter: {
        compare: (a: any, b: any) => a.invoiceNumber - b.invoiceNumber,
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      sorter: {
        compare: (a: any, b: any) => a.vendor.localeCompare(b.vendor),
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: {
        compare: (a: any, b: any) => a.dueDate - b.dueDate,
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: {
        compare: (a: any, b: any) => a.amount - b.amount,
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      sorter: {
        compare: (a: any, b: any) => a.currency.localeCompare(b.currency),
      },
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    // },
    // {
    //   title: 'Message',
    //   dataIndex: 'message',
    // },
  ];
  return columns;
};

const Invoice = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<InvoiceInterface | [] | any>([]);

  useEffect(() => {
    const data: InvoiceInterface[] = [];
    [...Array(1000)].forEach((number: number, index: number) => {
      const vendors = [
        'Dagens Industri Aktiebolag',
        'ASK Kloska GmbH',
        'DHL Express (Sweden) AB',
        'Rhenus Offshore Logistics GmbH',
        'If Skadeförsäkring AB (publ)',
        'Achilles Information Limited FPAL',
        'Glomar Shipmanagement B.V.',
      ];

      const invoiceNumber = 701273740 + index;
      const amount = (60000 + Math.random() * index).toFixed(2).toLocaleString();
      const currency = index % 2 === 0 ? 'SEK' : 'USD';
      // const status: any = index % 2 !== 0 ? <Tag color="red">Delayed</Tag> : <Tag color="green">Not Delayed</Tag>;
      // const message: any = index % 2 === 0 ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;

      data.push({
        invoiceNumber,
        vendor: vendors[Math.floor(Math.random() * 7)],
        dueDate: '11/12/2019',
        amount,
        currency,
        // status,
      });
    });
    setDataSource(data);
  }, []);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Invoices"
        subTitle="Allows recipients to copy existing reports."
      />
      <Table
        columns={getColumns()}
        dataSource={dataSource}
        onRow={(row) => ({
          onClick: () => {
            const { invoiceNumber } = row;
            navigate(`/invoice/${invoiceNumber}`);
          },
        })}
      />
    </>
  );
};
export default Invoice;
