import React from 'react';
import {
  Button,
  Checkbox,
  Col, Form, Input, Row, Typography,
} from 'antd';
import { Link } from 'react-router-dom';

const Login = () => (
  <Row justify="center" align="middle" style={{ height: '100%' }}>
    <Col span={12}>
      <Typography.Title level={1}>Login</Typography.Title>
      <Typography.Text>Welcome back, please login to your account.</Typography.Text>

      <Form
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        autoComplete="off"
        className="mt-32"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password' }]}
        >
          <Input.Password />
        </Form.Item>

        <Row align="middle" justify="space-between">
          <Form.Item name="remember" valuePropName="checked" className="mb-0">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Link to="/recover-password">
            Forgot Password?
          </Link>
        </Row>

        <Form.Item className="mt-16">
          <Link to="/dashboard">
            <Button block type="primary" htmlType="submit">
              Submit
            </Button>
          </Link>
        </Form.Item>
      </Form>
    </Col>
  </Row>
);
export default Login;
