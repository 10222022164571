import React from 'react';
import Error from '../../components/Error';
import { Error404 as Error404Illustration } from '../../components/Illustrations';

const Error404 = () => (
  <Error
    Image={<Error404Illustration />}
    Code="404"
    Message="Oops, Page not found!"
  />
);

export default Error404;
