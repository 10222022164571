import React from 'react';
import {
  Button,
  Card, Col, DatePicker, Form, Input, PageHeader, Row, Select,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

const Report = () => (
  <>
    <PageHeader
      className="site-page-header"
      title="Reports"
      subTitle="Allows you to create custom reports."
    />
    <Card>
      <Form
        name="basic"
        layout="vertical"
        autoComplete="off"
        size="large"
      >
        <Row gutter={24} align="bottom">
          <Col span="4">
            <Form.Item
              label="Invoice Number"
              name="invoiceNumber"
              tooltip="This is a required field"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span="4">
            <Form.Item
              label="Invoice ID"
              name="invoiceId"
              tooltip="This is a required field"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span="4">
            <Form.Item
              label="Order Number"
              name="orderNumber"
              tooltip="This is a required field"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span="4">
            <Form.Item name="vendorName" label="Vendor" tooltip="This is a required field">
              <Select>
                <Select.Option value="male">Cashew Payments</Select.Option>
                <Select.Option value="female">MultiBank</Select.Option>
                <Select.Option value="other">JJ Electronics</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span="4">
            <Form.Item name="currency" label="Currency" tooltip="This is a required field">
              <Select>
                <Select.Option value="male">USD</Select.Option>
                <Select.Option value="female">EUR</Select.Option>
                <Select.Option value="other">AED</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span="4">
            <Form.Item name="status" label="Status" tooltip="This is a required field">
              <Select>
                <Select.Option value="male">All</Select.Option>
                <Select.Option value="female">Processed</Select.Option>
                <Select.Option value="other">Sent</Select.Option>
                <Select.Option value="other">Paid</Select.Option>
                <Select.Option value="other">Cancelled</Select.Option>
                <Select.Option value="other">Invalidated</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} align="bottom">
          <Col span="4">
            <Form.Item name="status" label="Status" tooltip="This is a required field">
              <DatePicker.RangePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="text-right">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={(e) => {
                console.log(e);
              }}
              className="mr-16"
            >
              Advanced Search
              <DownOutlined className="ml-4" />
            </a>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>

    </Card>
  </>
);
export default Report;
