import React from 'react';
import Error from '../../components/Error';
import { Error500 as Error500Illustration } from '../../components/Illustrations';

const Error500 = () => (
  <Error
    Image={<Error500Illustration />}
    Code="500"
    Message="Something went wrong."
  />
);

export default Error500;
