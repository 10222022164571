import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  Layout, Row, Col,
} from 'antd';

const { Content } = Layout;

const Error = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Content className="bg-primary-1">
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col style={{ textAlign: 'center' }}>
          <Outlet />
        </Col>
      </Row>
    </Content>
  </Layout>
);
export default Error;
