import React from 'react';
import Error from '../../components/Error';
import { Error403 as Error403Illustration } from '../../components/Illustrations';

const Error403 = () => (
  <Error
    Image={<Error403Illustration />}
    Code="403"
    Message="Forbidden"
  />
);

export default Error403;
