import {
  Button,
  Card, Descriptions, PageHeader, Table, Tabs,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';

interface PreviousInvoiceInterface {
    invoiceNumber: number,
    invoiceDate: string,
    amount: string,
    currency: string,
    salesTax: number,
}

const getColumns = () => {
  const columns: ColumnsType<PreviousInvoiceInterface> = [
    {
      title: 'Invoice',
      dataIndex: 'invoiceNumber',
      sorter: {
        compare: (a: any, b: any) => a.invoiceNumber - b.invoiceNumber,
      },
    },
    {
      title: 'Date',
      dataIndex: 'invoiceDate',
      sorter: {
        compare: (a: any, b: any) => a.dueDate - b.dueDate,
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: {
        compare: (a: any, b: any) => a.amount - b.amount,
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      sorter: {
        compare: (a: any, b: any) => a.currency.localeCompare(b.currency),
      },
    },
  ];
  return columns;
};

const InvoiceDetail = () => {
  const [dataSource, setDataSource] = useState<PreviousInvoiceInterface | [] | any>([]);

  useEffect(() => {
    const data: PreviousInvoiceInterface[] = [];
    [...Array(10)].forEach((number: number, index: number) => {
      const invoiceNumber = 701273740 + index;
      const amount = (60000 + Math.random() * index).toFixed(2).toLocaleString();
      const currency = index % 2 === 0 ? 'SEK' : 'USD';
      const salesTax = 0.00;

      data.push({
        invoiceNumber,
        invoiceDate: '11/12/2019',
        amount,
        currency,
        salesTax,
      });
    });
    setDataSource(data);
  }, []);
  return (
    <div className="invoice-details">
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Invoice Detail"
        subTitle="A table displays rows of data."
        extra={[
          <Button key="page-header-3">Operation</Button>,
          <Button key="page-header-2" danger>Cancel</Button>,
          <Button key="page-header-1" type="primary">
            Forward
          </Button>,
        ]}
      />

      <Card>
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Invoice Number">21040324</Descriptions.Item>
          <Descriptions.Item label="Invoice Date">27/08/2021</Descriptions.Item>
          <Descriptions.Item label="Due Date">26/09/2021</Descriptions.Item>
          <Descriptions.Item label="Posting Date">27/08/2021</Descriptions.Item>
          <Descriptions.Item label="Gross Amount">61,201.28</Descriptions.Item>
          <Descriptions.Item label="Vendor">Glomar Shipmanagement B.V.</Descriptions.Item>
          <Descriptions.Item label="Order/Purchase No.">21040324</Descriptions.Item>
          <Descriptions.Item label="Verification No.">21040324</Descriptions.Item>
          <Descriptions.Item label="ERP Tran. No.">21040324</Descriptions.Item>
          <Descriptions.Item label="Sales Tax"> 0.00</Descriptions.Item>
          <Descriptions.Item label="Currency"> EUR</Descriptions.Item>
          <Descriptions.Item label="Rate"> 10.1972</Descriptions.Item>
          <Descriptions.Item label="Amount"> 624,081.69</Descriptions.Item>
          <Descriptions.Item label="Reference">
            GVA- 2018100- Mobilisation fee & Hire
            (08-2021)
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card className="mt-24">
        <Tabs defaultActiveKey="tab-pane-1">
          <Tabs.TabPane tab="Previous Invoices" key="tab-pane-1">
            <Table
              columns={getColumns()}
              dataSource={dataSource}
              bordered
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Messages" key="tab-pane-2">
            Internal communication between colleagues.
          </Tabs.TabPane>
          <Tabs.TabPane tab="Logs" key="tab-pane-3">
            Invoice history.
          </Tabs.TabPane>
          <Tabs.TabPane tab="Notes" key="tab-pane-4">
            Add a feature to add new vendor or search a vendor.
          </Tabs.TabPane>
        </Tabs>
      </Card>

    </div>
  );
};
export default InvoiceDetail;
